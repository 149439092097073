export default function TopBar({index, setIndex, count}) {

  return <div className="TopBar">
    <div style={{margin: '0 12px'}}>
      <button onClick={(e) => setIndex(0)} className={index === 0? "active" : ''}>全部</button>
      <button onClick={(e) => setIndex(1)} className={index === 1? "active" : ''}>已完成</button>
      <button onClick={(e) => setIndex(2)} className={index === 2? "active" : ''}>进行中</button>
      <button onClick={(e) => setIndex(3)} className={index === 3? "active" : ''}>已超时</button>
      <button
       style={{
         position: 'relative'}
       }
        onClick={(e) => setIndex(4)} className={index === 4? "active" : ''}>
        待支付
        {
          count && count > 0? <span className={'dzf-tisp'}>{count}</span>:<></>
        }
      </button>
    </div>
  </div>
}
