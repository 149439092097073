import {useEffect, useState} from "react";

export default function CzpyCountdown({czpt, load}){
  const [endTime, setEndTime] = useState();

  const formatTime = (time) => {

    let hour = parseInt(time / (60 * 60 * 1000));
    let minute = parseInt((time - hour * 60 * 60 * 1000) / (60 * 1000));
    let second = parseInt((time - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000);
    return { hour, minute, second };
  };

  useEffect(() => {

    if (czpt.paymentStu === 4){
      setEndTime(czpt.payEndTime || 0);
    }else {
      setEndTime(czpt.endTime || 0);
    }

    const interval = setInterval(() => {
      setEndTime((prevEndTime) => {
        if (prevEndTime <= 0) {
          load();
          clearInterval(interval);
          return prevEndTime
        }
        return prevEndTime - 1000
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [czpt.endTime]);

  if (czpt.state === 0 || czpt.state ===  2 || czpt.state ===3 || czpt.state ===4 ||  czpt.state === 5) {
    return null;
  }


  return   <div className="czpy-detail-countdown">
    {czpt.paymentStu === 4?'支付倒计时': '拼团倒计时'}：
    <span className="czpy-detail-countdown-time">
            <span className="box">{formatTime(endTime).hour}</span>:
            <span className="box">{formatTime(endTime).minute}</span>:
            <span className="box">{formatTime(endTime).second}</span>
          </span>
  </div>
}
