import Banner from "../../components/banner";
import banner from '../../../img/banner@3x.png'
import CzptItem from "../../components/czpt-item";
import XmptItem from "../../components/xmpt-item";
import {useContext, useEffect, useRef, useState} from "react";

import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


export default function Home(){
  // const scrollPositionRef = useRef(0);
  // const history = useHistory();

  // useEffect(() => {
  //   // 保存滚动位置
  //   scrollPositionRef.current = window.scrollY;
  //
  //   // 监听路由变化
  //   const unlisten = history.listen(() => {
  //     // 返回上个页面时恢复滚动位置
  //     // window.scrollTo(0, scrollPositionRef.current);
  //     setTimeout(()=>{
  //       window.scrollTo(0, scrollPositionRef.current);
  //     },300);
  //   });
  //
  //   return () => {
  //     unlisten(); // 取消监听
  //   };
  // }, []);

  const [open, setOpen] = useState(false);
  const [buyNumber, setBuyNumber] = useState(0);


  const handleOpen = (buyNumber) =>{
    setOpen(true)
    setBuyNumber(buyNumber)
  }


  function handleScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    localStorage.setItem("scrollPosition", scrollPosition)
    console.log('Scrolling...');
  }


  useEffect(function () {
    // 在组件挂载后，绑定滚动事件
    window.addEventListener('scroll', handleScroll);

    setTimeout(()=>{
      document.documentElement.scrollTop = parseInt(localStorage.getItem("scrollPosition")) || 0;
      document.body.scrollTop = parseInt(localStorage.getItem("scrollPosition")) || 0;
    },1000);

    // 组件卸载时，移除滚动事件监听
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])

  return <main className={"bg"}>
    <Helmet>
      <title>首页</title>
    </Helmet>
    <Banner src={banner}/>
    <CzptItem  handleOpen={handleOpen}/>
    <XmptItem handleOpen={handleOpen}/>
    <button className={"wdpt-btn"}>
      <Link to={"/wdpt"}>我的拼团</Link>
    </button>
    <div className={"dialog-box-bg"} onClick={()=> setOpen(false)} style={open? {display: 'flex'}: {display: 'none'}}>
      <div className="dialog-box">
        <div className={"dialog-box-content"}>
          <p> 活动期间，该拼团仅限参加{buyNumber}次
            您已参与{buyNumber}次，请在我的拼团中查看</p>
          <Link to={"/wdpt"}>
            <button></button>
          </Link>
        </div>
      </div>
    </div>
  </main>
}
