export function substr(value,length){
  if (value.length > length){
    return value.substr(0, length) + '...'
  }
  return value
}


export const formatTime = (time) => {
  let hour = parseInt(time / (60 * 60 * 1000));
  let minute = parseInt((time - hour * 60 * 60 * 1000) / (60 * 1000));
  let second = parseInt((time - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000);
  return { hour, minute, second };
};


export function getItem(key) {
  try{
    return JSON.parse(localStorage.getItem(key))
  }catch (e) {
    return null;
  }
}

export function setItem(key, value) {
 if (value)
   try {
     localStorage.setItem(key, JSON.stringify(value))
   }catch (e) {
   }

}


export function checkOrderPay(sn){
  if (sn === null)
    return false;

  let order = getItem("order_" +sn)

  if (order == null)
    return false

  if (order.paymentStu === 1){
    return true
  }
}

export function setOrderPay(sn, order){
  setItem("order_" +sn, order)
}
