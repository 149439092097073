import Avatar from "../avatar";
import other_avatar from '../../../img/132.jpeg'
import {addShare, domainUrl, getActivityCreate, getOtherActivity} from "../../api";
import {useContext, useEffect, useState} from "react";
import TokenContext from "../../TokenContext";
import {Link, useLocation} from "react-router-dom";

import sharIcon from './../../../img/分享图标.png'
import pageImg from "../../../img/分享背景图B@2x20241111.png";
import CustomAlert from "../custom-alert";

export default function CzptParticipant({czpt, load}){

  const {token} = useContext(TokenContext)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dumplingCode = searchParams.get("dumplingCode")
  const type = searchParams.get("type");
  const memberId = searchParams.get("memberId")
  const shareMemberId = searchParams.get("shareMemberId")
  const from = searchParams.get("from")
  const [paying, setPaying] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(false);


  const handleShowAlert = (message) => {
    setShowAlert(true);
    setMessage(message)
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setMessage(null)
  };

  const getQtcz = async () => {
    let resp = await getOtherActivity(token);
    console.log(resp)
    if (resp.code ===200){
      setQtczImg(resp.result.dictDataName)
    }
  }
  const toPay = async () =>{

    let order = czpt.orderActivity;

    if (!order){
      let resp = await getActivityCreate(czpt.activityId, dumplingCode,token);

      if (!resp.code || resp.code !== 200){
        return  handleShowAlert(resp.message || "订单创建失败")
      }
      order = resp.result;
    }

    if (!order){
      return  handleShowAlert("订单不存在")
    }


    let path  = "/pages/index/webPay";
    path += "?orderSn="+ order.orderCode;
    path += "&price=" + order.orderActivityDo * 100;
    path += "&goodsName=" + order.projectName;
    path += "&type=32"
    let backUrl =  domainUrl + "/czpt20241111/" + czpt.activityId;
    backUrl += "?type=payMsg"
    backUrl += '&islogin=1'
    backUrl += "&dumplingCode=" + order.dumplingCode
    backUrl += "&isnosharebtn=true"
    if (type === 'share'){
      backUrl += "&from=" + 'share';
    }
    let successH5Url = backUrl + "&pay_msg=" + 'pay_success';
    let errorH5Url = backUrl + '&pay_msg='+ 'pay_error'

    console.log("successH5Url:"+successH5Url)
    console.log("errorH5Url:"+backUrl)

    path += "&successH5UrlBase64UriCode="+ encodeURIComponent(btoa(successH5Url)) ;
    path += "&errorH5UrlBase64UriCode="+ encodeURIComponent(btoa(errorH5Url));


    console.log('充值拼团支付小程序path:'+path)
    setPaying(true)
    setInterval(function () {
      load()
      setPaying(false)
    }, 3000)

    wx.miniProgram.navigateTo({url:path})

  }

  const requestShareApi = async () => {
    if (shareMemberId === memberId) return;
    await addShare(czpt.activityId, shareMemberId, memberId, '0', token)
  }

  useEffect(function () {
   if (type && type === 'share'){
      requestShareApi().then(r => {})
   }
  },[])

  const toShare = () => {
    let  url = "/pages/index/webShare";

    let order = czpt.orderActivity;
    if (!dumplingCode && !order.dumplingCode){
      handleShowAlert("请先参团。")
    }

    let MEMBER_ID = localStorage.getItem('MEMBER_ID');

    let h5Url = domainUrl + "/czpt20241111/" + czpt.activityId;
    h5Url += "?dumplingCode=" + (dumplingCode || order.dumplingCode);
    h5Url += "&type=share";
    h5Url += '&shareMemberId=' + MEMBER_ID;
    h5Url += '&islogin=1'
    h5Url += "&isnosharebtn=true"


    h5Url = btoa(h5Url);
    h5Url = encodeURIComponent(h5Url);

    //分享链接为path
    let path = "/pages/index/webView?webViewSrcBase64UriCode=" + h5Url;
    path = btoa(path);
    path = encodeURIComponent(path);


    url += "?pathBase64UriCode=" + path;
    url += "&title=" + czpt.activityName;
    url += "&pageTitle=" + czpt.activityName;
    url += "&btnStyle=1";
    // url += "&imageUrl=" + sharImg;
    url += "&imageUrl=" + pageImg;
    url += "&pageImageUrl=" + pageImg;
    console.log("分享：" + path)
    console.log(url)
    wx.miniProgram.navigateTo({ url: url });
  }

  const getParticipant = () => {
    if (!czpt.dumplingResponse || czpt.dumplingResponse.length == 0){
      return <>
        <Avatar className="avatar" src={czpt.avatar} title={"发起人"} />
        {Array.apply(null, Array(czpt.peopleNumber -1)).map(function (_, index) {
          return <Avatar key={index} className="avatar" />
        })}
      </>
    }

    return <>
      {czpt.dumplingResponse.map(function (dumpling,index) {
        if(dumpling.oadmin === 1){
          return <Avatar key={index} src={dumpling.avatar} className="avatar" title={"发起人"} />
        }
      })}
      {Array.apply(null, Array(czpt.peopleNumber)).map(function (_, index) {
          if (czpt.dumplingResponse[index] && czpt.dumplingResponse[index].oadmin === 1){
              return <></>
          }
          if (czpt.dumplingResponse[index] && !czpt.dumplingResponse[index].avatar){
            return <Avatar key={index} src={other_avatar} className="avatar" />
          }
          return <Avatar key={index} src={czpt.dumplingResponse[index]?.avatar} className="avatar" />
      })}
    </>
  }

  return <div className="czpy-detail-participant">
    <div className="avatars-container">
      {
        getParticipant()
      }
    </div>
    {
      (czpt.state === 1 && czpt.paymentStu === 4) ||
      (czpt.state === 0 || czpt.paymentStu === 4)  ||
      (czpt.state === 0 && czpt.paymentStu === null) ||
      (czpt.state === 0 && czpt.paymentStu === 0)?  <>
        <p className="czpy-detail-participant-text">{type === 'share'? '他们正在拼团，参与可立即拼成':'拼团尽享超值优惠，快来参加吧'}</p>
        <button onClick={toPay} disabled={paying} style={{
          width: '100%',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)'
        }}>

          {
            paying?'检查支付中...':
            type && type === 'share' ? czpt.state === 1? '立即支付 ': '参与拼团' : '立即支付'}

        </button>
      </> : <></>
    }
    {
      czpt.state === 1 && czpt.paymentStu === 1?  <>
        <p className="czpy-detail-participant-text">你已参与此团，分享好友更快成团</p>
        <button style={{
          width: '97px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
          marginRight: '10px'
        }}
        onClick={toShare}
        >
          <img src={sharIcon} className={"icon"} />
          分享</button>
        <button style={{
          width: '97px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)'
        }}><Link to={"/wdpt"}>我的拼团</Link></button>

        {
          type==="share" || from === 'share' ?
            <button style={{
            width: '97px',
            fontSize: '14px',
            height: '46px',
            padding: '15px',
            marginTop: '10px',
            borderRadius: '24px',
            background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
            marginLeft: '10px'
          }}><Link to={"/at20241111"}>更多拼团</Link></button>
            : <></>
        }
      </> : <></>
    }
    {/*已成功*/}
    {czpt.state === 2 && czpt.paymentStu === 1?  <>
    <p className="czpy-detail-participant-text">恭喜您，拼团已成功</p>
    <button style={{
      width: '108px',
      fontSize: '14px',
      height: '46px',
      padding: '15px',
      marginTop: '10px',
      borderRadius: '24px',
      background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
      marginRight: '10px'
    }}><Link to={"/wdpt"}>我的拼团</Link></button>
    <button style={{
      width: '108px',
      fontSize: '14px',
      height: '46px',
      padding: '15px',
      marginTop: '10px',
      borderRadius: '24px',
      background: 'linear-gradient(276deg, #FE6936, #FB3F48)'
    }}><Link to={"/at20241111"}>更多拼团</Link></button>
  </> : <></>
    }
    {/*已成功结束*/}
    {/*拼团已超时 */}
    {
      czpt.state === 3?  <>
        <p className="czpy-detail-participant-text">抱歉，拼团已超时，请参与其他拼团</p>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
          marginRight: '10px'
        }}>
          <Link to={"/wdpt"}>我的拼团</Link>
        </button>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
          marginRight: '10px'
        }}>
          <Link to={"/at20241111"}>更多拼团</Link>
        </button>
      </> : <></>
    }
    {/*拼团已超时结束 */}

    {/*拼团人已满 */}
    {
      czpt.state === 4?  <>
        <p className="czpy-detail-participant-text">此团已拼满，请参与其他拼团。</p>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
          marginRight: '10px'
        }}>
          <Link to={"/wdpt"}>我的拼团</Link>
        </button>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)'
        }}>
          <Link to={"/at20241111"}>更多拼团</Link>
        </button>
      </> : <></>
    }
    {/*拼团人已满结束 */}

    {/*不能参团 */}
    {
      czpt.state === 5?  <>
        <p className="tips">很抱歉，您不能参与此团！</p>
        <p className="czpy-detail-participant-text">活动期间，{czpt.activityName}项目仅限参加{czpt.buyNum}次，您已参与{czpt.buyNum}次,
          请在<Link to={"/wdpt"}>我的拼团</Link>中查看；
        </p>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)',
          marginRight: '10px'
        }}>
          <Link to={"/wdpt"}>我的拼团</Link>
        </button>
        <button style={{
          width: '108px',
          fontSize: '14px',
          height: '46px',
          padding: '15px',
          marginTop: '10px',
          borderRadius: '24px',
          background: 'linear-gradient(276deg, #FE6936, #FB3F48)'
        }}>
          <Link to={"/at20241111"}>更多拼团</Link>
        </button>
      </> : <></>
    }
    {/*不能参团结束 */}


    {showAlert && (
      <CustomAlert
        message={message}
        onClose={handleCloseAlert}
      />
    )}
  </div>
}

