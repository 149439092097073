import TopBar from "../../components/topbar";
import {useEffect, useState} from "react";
import WdptItem from "../../components/wdpt-item";
import {Helmet} from "react-helmet";

export default function Wdpt() {

  const [paymentStu, setPaymentStu] = useState(0)
  const [count, setCount] = useState(0)
  
  return <div className="App-page">
    <Helmet>
      <title>我的拼团</title>
    </Helmet>
    <TopBar index={paymentStu} count={count} setIndex={setPaymentStu}/>
    <WdptItem paymentStu={paymentStu} setCount={setCount}/>
  </div>

}
