import {useContext, useEffect, useState} from "react";
import TokenContext from "../../TokenContext";
import {getOtherActivity, getProjectRule, getRules} from "../../api";
import {useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Gz(){

  const {token} = useContext(TokenContext);
  const [rule, setRule] = useState({});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const type = searchParams.get("type")
  const id = searchParams.get("id")

  const getCzRules = async () => {
    if (!token){
      return
    }
    let resp = await getProjectRule(id, type, token);
    // document.title = resp.result?.orderActivityName || '';
    if (resp.code ===200){
      setRule(resp.result || {})
    }
  }

  useEffect(function () {
    getCzRules().then(r => {})
  },[token])

  return <main>
    <Helmet>
      <title>{setRule.orderActivityName || ''}</title>
    </Helmet>
    <section>
      <div className="czpt-activity-detail">
        <h4 className="czpt-activity-detail-title">{rule.orderActivityName}</h4>
        <div className="czpt-activity-detail-context">
          <div className={"htmlContent"} dangerouslySetInnerHTML={{ __html: rule.orderActivityDo }}>
          </div>
        </div>
      </div>
    </section>
  </main>
}
