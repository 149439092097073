import {useEffect, useRef, useState} from "react";

export default function XmptCountdown({project, load}) {
  const [endTime, setEndTime] = useState(0);
  const intervalRef = useRef(null);
  const formatTime = (time) => {

    let hour = parseInt(time / (60 * 60 * 1000));
    let minute = parseInt((time - hour * 60 * 60 * 1000) / (60 * 1000));
    let second = parseInt((time - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000);
    return {hour, minute, second};
  };

  useEffect(() => {

    console.log("useEffect")
    if (project.paymentStu === 4) {
      setEndTime( project.payEndTime || 0);
    } else {
      setEndTime(project.endTime);
    }

    intervalRef.current = setInterval(() => {
      console.log("intervalRef.current:" + endTime)
      setEndTime((prevEndTime) => {
        console.log("setEndTime:" + prevEndTime)
        if (prevEndTime === 0 || prevEndTime  < 0) {
          console.log("clearInterval:" + prevEndTime)
          load();
          clearInterval(intervalRef.current);
          return prevEndTime
        }
        return prevEndTime - 1000
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [project.endTime]);

  if (project.state === 0 || project.state === 2 || project.state === 3 || project.state === 4 || project.state === 5) {
    return null;
  }


  return <div className="czpy-detail-countdown">
    {project.paymentStu === 4 ? '支付倒计时' : '拼团倒计时'}：
    <span className="czpy-detail-countdown-time">
            <span className="box">{formatTime(endTime).hour}</span>:
            <span className="box">{formatTime(endTime).minute}</span>:
            <span className="box">{formatTime(endTime).second}</span>
          </span>
  </div>
}
