import Home from "./pages/home";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Czpt from "./pages/czpt";
import CzptGz from "./pages/czpt-gz";
import Qtcz from "./pages/qtcz";
import {login} from "./api";
import { useContext, useEffect, useState} from "react";
import crypto from 'crypto-js'
import Wdpt from "./pages/wdpt";
import TokenContext from "./TokenContext";
import Xmpt from "./pages/xmpt";
import Gz from "./pages/czpt-gz2";

export function App() {

  const {token, updateToken} = useContext(TokenContext)
  const searchParams = new URLSearchParams(location.search);


  const memberId = searchParams.get("memberId")
  const timestamp = searchParams.get("timestamp")
  const param = searchParams.get("param")
  const sign = searchParams.get("sign")
  const [error, setError] = useState(null);


  const requestLogin = async () => {
    let SESSION_TOKEN = localStorage.getItem('SESSION_TOKEN')
    try {
      if (memberId && timestamp && sign){
        let resp = await login(
          {
            memberId,
            timestamp: timestamp + '',
            sign,
            param
          }
        );
        if (!resp.data) {
          throw new Error(
            JSON.stringify(resp)
          )
        } else if (!resp.data.token) {
          throw new Error(
            JSON.stringify(resp)
          )
        }

        SESSION_TOKEN = resp.data.token
        localStorage.setItem('SESSION_TOKEN', SESSION_TOKEN)
        localStorage.setItem('MEMBER_ID', memberId)
      }

      if (!SESSION_TOKEN){
        throw new Error(
          ""
        )
      }
      updateToken(SESSION_TOKEN);

    } catch (e) {
      console.error(e)
      alert(`登录失败`)
      setError(`登录失败，参数有误`)
    }
  }


  useEffect(function () {
    requestLogin().then(r => {
    })
  },[])
  if (!token){
    return <div style={{marginTop: '130px'}}>
      {error ?
        <></>
        :
        <div className="loading">
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
        </div>
      }
      {
        error? <p style={{textAlign: 'center', color: 'rgb(255 255 255)'}}>{error}</p>
        : <p style={{textAlign: 'center', color: 'rgb(255 255 255)'}}>加载中</p>
      }

    </div>
  }
  //修改h5部分路由日期标识限制旧活动分享链接的访问。
  return <Router>
      <Routes>
        <Route exact path="/at20241111" element={<Home/>}/>
        <Route exact path="/qtcz" element={<Qtcz/>}/>
        <Route exact path="/czpt/gz" element={<CzptGz/>}/>
        <Route exact path="/gz" element={<Gz/>}/>
        <Route exact path="/czpt20241111/:itemId" element={<Czpt/>}/>
        <Route exact path="/xmpt20241111/:itemId" element={<Xmpt/>}/>
        <Route exact path="/wdpt" element={<Wdpt/>}/>
      </Routes>
    </Router>;

}
