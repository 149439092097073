import { useEffect, useState } from "react";
import {formatTime} from "../../utils";

export default function WdptCountdown({ czpt, load, title }) {
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    if (!czpt.endTime) {
      return;
    }

    setEndTime(czpt.endTime);

    const interval = setInterval(() => {
      setEndTime((prevEndTime) => {
        if (prevEndTime <= 0) {
          clearInterval(interval);
          if (load) load();
          return 0;
        }
        return prevEndTime - 1000;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [czpt.endTime]);

  if (!czpt.endTime) {
    return null;
  }

  const { hour, minute, second } = formatTime(endTime);

  return (
    <div className="czpy-detail-countdown">
      {title ? title : '拼团倒计时'}：
      <span className="czpy-detail-countdown-time">
        <span className="box">{hour}</span>:
        <span className="box">{minute}</span>:
        <span className="box">{second}</span>
      </span>
    </div>
  );
}
